import { screenHeight, screenWidth } from "../../common/commonConstants";
import { gravity } from "../canvasConstants";

export class Circle {
  constructor(x, y, dx, dy, radius) {
    this.x = x;
    this.y = y;
    this.dx = dx;
    this.dy = dy;
    this.radius = radius;
    this.movementCounter = 0;
    this.offScreen = false;
    this.isHitSideOfHole = false;
    this.isSuccess = false;
  }

  drawSmiley(ctx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    ctx.fillStyle = "yellow";
    ctx.fill();
    ctx.strokeStyle = "black";
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(this.x - 10, this.y - 10, 2, 0, Math.PI * 2, false);
    ctx.fillStyle = "black";
    ctx.fill();
    ctx.strokeStyle = "black";
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(this.x + 10, this.y - 10, 2, 0, Math.PI * 2, false);
    ctx.fillStyle = "black";
    ctx.fill();
    ctx.strokeStyle = "black";
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(this.x, this.y + 5, 5, 0, Math.PI, true);
    ctx.fillStyle = "black";
    ctx.fill();
    ctx.strokeStyle = "black";
    ctx.stroke();
  }

  drawSmileySuccess(ctx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    ctx.fillStyle = "green";
    ctx.fill();
    ctx.strokeStyle = "black";
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(this.x - 10, this.y - 10, 2, 0, Math.PI * 2, false);
    ctx.fillStyle = "black";
    ctx.fill();
    ctx.strokeStyle = "black";
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(this.x + 10, this.y - 10, 2, 0, Math.PI * 2, false);
    ctx.fillStyle = "black";
    ctx.fill();
    ctx.strokeStyle = "black";
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(this.x, this.y - 3, 7, 0, Math.PI, false);
    ctx.fillStyle = "black";
    ctx.fill();
    ctx.strokeStyle = "black";
    ctx.stroke();
  }

  update(ctx) {
    if (this.x - this.radius > screenWidth || this.x + this.radius < 0) {
      this.offScreen = true;
    }
    if (this.y + this.radius + this.dy > screenHeight) {
      this.dy = 0;
    }

    if (this.isHitSideOfHole) {
      this.x -= this.dx;
    } else {
      this.x += this.dx;
    }
    this.y += this.dy;

    if (this.movementCounter % 100) {
      this.dy += gravity;
    }
    if (this.movementCounter > 200) {
      if (this.y + this.radius + this.dy > screenHeight && this.dx > 0) {
        this.dx -= 1;
      }
      this.movementCounter = 0;
    } else {
      this.movementCounter++;
    }

    if (this.isSuccess) {
      this.dx = 0;
      this.dy = 0;
      this.drawSmileySuccess(ctx);
    } else {
      this.drawSmiley(ctx);
    }
  }
}
