import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useRecoilValue } from "recoil";
import {
  shotsFiredState,
  bucketsState,
  isBucketsGameOnState,
} from "../../atoms";

import { screenHeight } from "../../common/commonConstants";

const ScoreCard = ({ shootBall }) => {
  const shotsFired = useRecoilValue(shotsFiredState);
  const buckets = useRecoilValue(bucketsState);
  const isBucketsGameOn = useRecoilValue(isBucketsGameOnState);

  return (
    <Box
      onClick={shootBall}
      sx={{
        position: "fixed",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 100,
        top: isBucketsGameOn ? "0px" : `${screenHeight + 300}px`,
        transition: "1s",
      }}
    >
      <Paper sx={{ padding: "2em", margin: "1em" }} elevation={6}>
        {`Buckets: ${buckets}`}
        <br />
        {`Shots Fired: ${shotsFired}`}
      </Paper>
    </Box>
  );
};

export default ScoreCard;
