import { atom } from "recoil";

export const shotsFiredState = atom({
  key: "shotsFiredState",
  default: 0,
});

export const bucketsState = atom({
  key: "bucketsState",
  default: 0,
});

export const isMenuOpenState = atom({
  key: "menuState",
  default: false,
});

export const isBucketsGameOnState = atom({
  key: "isBucketsGameOnState",
  default: false,
});

export const isBucketsExampleModalOpenState = atom({
  key: "isBucketsExampleModalOpenState",
  default: false,
});

export const isBucketsExampleModalFirstTimeOpenState = atom({
  key: "isBucketsExampleModalFirstTimeOpenState",
  default: false,
});
