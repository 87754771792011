import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MainPageButton from "./components/MainPageButton";
import { Link } from "react-router-dom";

import "./Transitions.css";

const HomePage = () => {
  return (
    <Grid
      container
      className="slit-in-vertical"
      sx={{
        flexGrow: 1,
        padding: "20px",
        maxWidth: { md: "500px" },
        textAlign: { md: "left" },
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h2"
          component="div"
          sx={{ fontFamily: `'Montserrat', sans-serif`, color: "white" }}
        >
          Welcome
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            fontFamily: `'Montserrat', sans-serif`,
            color: "white",
            marginBottom: "1em",
          }}
        >
          You've come to the right place
        </Typography>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Link to="/about">
            <MainPageButton title="About Me" />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link to="/work">
            <MainPageButton title="Work" />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link to="/contact">
            <MainPageButton title="Contact" />
          </Link>
        </Grid>
        {/* <Grid item xs={12}>
          <Link to="/privacy">
            <MainPageButton title="Privacy" />
          </Link>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default HomePage;
