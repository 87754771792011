import * as React from "react";

import Grid from "@mui/material/Grid";

import { isBucketsGameOnState } from "../atoms";
import { useRecoilValue } from "recoil";

import { Routes, Route } from "react-router-dom";

import ResponsiveAppBar from "./components/ResponsiveAppBar";

import HomePage from "./HomePage";
import AboutMePage from "./AboutMePage";
import WorkPage from "./WorkPage";
import ContactPage from "./ContactPage";

const MainPage = () => {
  const isBucketsGameOn = useRecoilValue(isBucketsGameOnState);

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        top: isBucketsGameOn ? "-2000px" : "0px",
        transition: "1s",
        backgroundColor: "initial",
        zIndex: 100,
        width: "100%",
      }}
    >
      <Grid container sx={{ padding: { md: "30px" } }}>
        <ResponsiveAppBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutMePage />} />
          <Route path="/work" element={<WorkPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </Grid>
    </div>
  );
};

export default MainPage;
