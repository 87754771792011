import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";

import { Link } from "react-router-dom";

import aboutMeImage from "../../assets/aboutme_sqr.jpg";

import {
  isMenuOpenState,
  isBucketsGameOnState,
  isBucketsExampleModalOpenState,
  isBucketsExampleModalFirstTimeOpenState,
} from "../../atoms";
import { useRecoilState } from "recoil";

import { screenHeight } from "../../common/commonConstants";

const ResponsiveAppBar = () => {
  const [isBucketsExampleModalOpen, setIsBucketsExampleModalOpen] =
    useRecoilState(isBucketsExampleModalOpenState);
  const [
    isBucketsExampleModalFirstTimeOpen,
    setIsBucketsExampleModalFirstTimeOpen,
  ] = useRecoilState(isBucketsExampleModalFirstTimeOpenState);
  const [isBucketsGameOn, setIsBucketsGameOn] =
    useRecoilState(isBucketsGameOnState);

  const toggleIsBucketsGameOn = () => {
    setIsBucketsGameOn(!isBucketsGameOn);
    if (!isBucketsExampleModalFirstTimeOpen) {
      setTimeout(() => {
        setIsBucketsExampleModalOpen(true);
        setIsBucketsExampleModalFirstTimeOpen(true);
      }, 1000);
    }
  };

  return (
    <Container maxWidth="xl">
      <Toolbar disableGutters>
        <IconButton
          sx={{
            mr: 2,
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Link to="/">
            <Avatar
              alt="Courtney Maragh"
              src={aboutMeImage}
              sx={{ width: "80px", height: "80px" }}
            />
          </Link>
        </IconButton>

        <Box sx={{ flex: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
          ></IconButton>
        </Box>
        <IconButton sx={{ flex: 1, display: { xs: "flex", md: "none" } }}>
          <Link to="/">
            <Avatar
              alt="Courtney Maragh"
              src={aboutMeImage}
              sx={{ width: "80px", height: "80px" }}
            />
          </Link>
        </IconButton>

        <Box sx={{ flex: 1 }}>
          <Tooltip title="Shoot your shot!">
            <Button
              onClick={toggleIsBucketsGameOn}
              sx={{
                my: 2,
                color: "white",
                fontSize: 10,
                display: "block",
                position: "relative",
                transition: "1s",
                border: "1px solid #ededed",
                borderRadius: "40px",
                padding: "1em",
                marginLeft: "auto",
                top: isBucketsGameOn ? `${screenHeight - 100 + 2000}px` : "0px",
                backgroundImage: isBucketsGameOn
                  ? "linear-gradient(to right, rgba(217, 214, 218, 1), rgba(208, 135, 249, 1))"
                  : "linear-gradient(to right, rgba(217, 214, 218,.5), rgba(208, 135, 249,.5))",
              }}
            >
              {" "}
              {isBucketsGameOn ? (
                <React.Fragment>
                  Back To
                  <br />
                  Site
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Shoot Some
                  <br />
                  Buckets
                </React.Fragment>
              )}
            </Button>
          </Tooltip>
        </Box>
      </Toolbar>
    </Container>
  );
};
export default ResponsiveAppBar;
