import { screenWidth, screenHeight } from "../../common/commonConstants";

export class Hole {
  constructor() {
    this.x = screenWidth / 2;
    this.y = screenHeight / 2;
    this.isTopOfHoleHit = false;
    this.precisionConstant = 20;
  }
  draw(ctx) {
    ctx.fillStyle = "#9676A8";
    ctx.fillRect(this.x - 30, this.y, 60, 50);
    ctx.stroke();

    ctx.beginPath();
    // ctx.arc(this.x, this.y, 4, 0, Math.PI * 2, false);
    ctx.ellipse(this.x, this.y, 30, 4, 0, 0, Math.PI * 2, false);
    ctx.fillStyle = "black";
    ctx.fill();
    ctx.strokeStyle = "black";
    ctx.stroke();
  }

  hitTopOfHole(ballX, ballY) {
    if (
      ballX <= this.x + this.precisionConstant + 40 &&
      ballX >= this.x - this.precisionConstant - 40 &&
      ballY >= this.y - 70 - this.precisionConstant &&
      ballY <= this.y - 70 + this.precisionConstant
    ) {
      this.isTopOfHoleHit = true;
    }
  }

  getHitSideOfHole(ballX, ballY, radius) {
    if (
      ballX + radius >= this.x - 30 &&
      ballX + radius <= this.x + 30 &&
      ((ballY >= this.y - 10 && ballY <= this.y + 50) ||
        (ballY - radius >= this.y + 30 && ballY - radius <= this.y + 50))
    ) {
      return true;
    } else {
      return false;
    }
  }

  resetHole() {
    this.isTopOfHoleHit = false;
  }

  getRandomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }

  successHole(ballX, ballY, makeBucketsCallback) {
    if (this.isTopOfHoleHit) {
      if (
        ballX <= this.x + this.precisionConstant &&
        ballX >= this.x - this.precisionConstant &&
        ballY >= this.y - this.precisionConstant &&
        ballY <= this.y + this.precisionConstant
      ) {
        this.x = this.getRandomNumber(150, Math.min(screenWidth - 40, 500));
        this.y = this.getRandomNumber(150, screenHeight - 150);
        makeBucketsCallback();
        this.isTopOfHoleHit = false;

        return true;
      }
    }
  }
}
