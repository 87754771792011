import React from "react";

import { Grid, Typography } from "@mui/material";

import { screenHeight } from "../common/commonConstants";

import "./Transitions.css";

const AboutMePage = () => {
  return (
    <Grid
      className="slit-in-vertical"
      container
      sx={{
        padding: "20px",
        height: screenHeight - 100,
        overflow: "auto",
        maxWidth: { md: "800px" },
        textAlign: "left",
      }}
    >
      <Grid item>
        <Typography
          variant="h2"
          component="div"
          sx={{ fontFamily: `'Montserrat', sans-serif`, color: "white" }}
        >
          About Me
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          sx={{
            color: "white",
            marginTop: "1em",
            paddingBottom: "40px",
            letterSpacing: 1,
          }}
        >
          I'm Courtney Maragh, a mobile and web developer with too many
          interests to count and an unconventional journey that led me to where
          I am today. I've always been a numbers guy at heart and enjoyed the
          problem-solving challenges that came with them. So I decided to get a
          degree in Mathematics with no clue on how I would use it. After
          scratching off teaching and research, I chose the righteous path of
          actuarial science (don't worry. Most people haven't heard the word. I
          promise you're not alone!). The important thing to know about
          actuaries is they're the guys to blame when your insurance rates go
          up. Fast forward a bit and I had an opportunity to work on a major
          project that involved intensive programming. After getting a taste, I
          was hooked.
          <br />
          <br />
          From a technical standpoint, I've ended up primarily in the front-end
          space, however I have worked with back-end technologies as well. I
          have extensive experience with React and React Native, and have
          contributed to projects involving both. I enjoy architecting custom
          components, API integrations (REST, GraphQL) and I work regularly with
          state management tools (Redux, Recoil, creating custom state
          middleware, etc.) My love/hate relationship with CSS has given me
          experience with layout models like Flexbox and CSS Grid, CSS
          variables, media queries, and more.
          <br />
          <br />
          I've found that tech merges my love for creating with my insatiable
          need to solve problems. This has enabled me to become a valuable asset
          to any development team or those looking to explore their own ideas
          but lack the knowhow of coding (or the time to get things done). I
          take pride in my work and I look forward to working with people, as I
          enjoy collaboration and seeing the results of joint efforts. If you're
          interested in getting in contact, please don't hesitate to reach out!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AboutMePage;
