import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CardMedia from "@mui/material/CardMedia";

import bucketsExample from "../../assets/buckets_example.gif";

import { useRecoilState } from "recoil";
import { isBucketsExampleModalOpenState } from "../../atoms";
import Button from "@mui/material/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BucketsExampleModal = ({ isOpen }) => {
  const [isBucketsExampleModalOpen, setIsBucketsExampleModalOpen] =
    useRecoilState(isBucketsExampleModalOpenState);

  const handleClose = () => setIsBucketsExampleModalOpen(false);

  return (
    <Modal
      open={isBucketsExampleModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Shoot Some Buckets!
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, paddingTop: "1em", paddingBottom: "1em" }}
        >
          If you have some time to kill, click (or tap) to try and see how many
          shots you can land inside the buckets!
          <br />
          <br />
          ... It's actually kind of addicting.
        </Typography>
        <CardMedia
          component="img"
          height="300"
          image={bucketsExample}
          alt="..."
        />
        <Button
          onClick={handleClose}
          sx={{ position: "absolute", top: "10px", right: "10px" }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};
export default BucketsExampleModal;
