import React from "react";
import Grid from "@mui/material/Grid";
import WorkCard from "./components/WorkCard";
import { screenHeight } from "../common/commonConstants";
import Typography from "@mui/material/Typography";

import babyChefImg from "../assets/baby_chef_wide.jpg";
import bugsImg from "../assets/bugs_wide.jpg";
import policeImg from "../assets/police_wide.jpg";
import loanCalcImg from "../assets/loan_calc_wide.jpg";
import dndImg from "../assets/dnd.png";

import "./Transitions.css";

import portfolioData from "../data/portfolio.json";

const imgArr = [dndImg, bugsImg, loanCalcImg, babyChefImg, policeImg];

const WorkPage = () => {
  const _renderWorkCards = () => {
    const portfolioWorkCards = portfolioData.data.map((work, index) => {
      return (
        <Grid item xs={12} md={6}>
          <WorkCard
            key={work.title}
            img={imgArr[index]}
            title={work.title}
            description={work.description}
            site={work.site}
            git={work.git}
            siteLinkText={work.siteLinkText}
          />
        </Grid>
      );
    });
    return portfolioWorkCards;
  };

  return (
    <Grid
      className="slit-in-vertical"
      container
      sx={{
        padding: "20px",
        height: screenHeight - 100,
        overflow: "auto",
        textAlign: "left",
        maxWidth: { md: "800px" },
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h2"
          component="div"
          sx={{ fontFamily: `'Montserrat', sans-serif`, color: "white" }}
        >
          Work
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="p"
          component="div"
          sx={{
            fontFamily: `'Montserrat', sans-serif`,
            color: "white",
            marginBottom: "20px",
          }}
        >
          Below is a compilation of some of my recent work. Feel free to click
          on any project below! Each has a short description of the project and
          two links: one to a hosted version and the other to its GitHub repo.
        </Typography>
      </Grid>
      {_renderWorkCards()}
    </Grid>
  );
};

export default WorkPage;
