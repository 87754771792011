import React from "react";
import Grid from "@mui/material/Grid";
import { screenHeight } from "../common/commonConstants";
import Typography from "@mui/material/Typography";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import "./Transitions.css";
import Button from "@mui/material/Button";

const ContactPage = () => {
  return (
    <Grid
      className="slit-in-vertical"
      container
      sx={{
        flexGrow: 1,
        padding: "20px",
        maxWidth: { md: "500px" },
        textAlign: { md: "left" },
        color: "white",
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h2"
          component="div"
          sx={{ fontFamily: `'Montserrat', sans-serif` }}
        >
          Contact
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="div"
          component="div"
          sx={{
            fontFamily: `'Montserrat', sans-serif`,
            color: "white",
            marginBottom: "1em",
          }}
        >
          Feel free to contact me via any medium below, or visit my GitHub!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          href="mailto: maraghcourtney@gmail.com"
          sx={{
            my: 2,
            color: "white",
            fontSize: 10,
            position: "relative",
            transition: "1s",
            border: "1px solid #ededed",
            borderRadius: "40px",
            textAlign: "left",
            padding: "1em",
            width: "100%",
          }}
        >
          <div style={{ display: "flex" }}>
            <AlternateEmailIcon sx={{ marginRight: "10px" }} />
            <div>
              <Typography>E-mail</Typography>
              <Typography sx={{ fontSize: "10px", fontStyle: "italic" }}>
                maraghcourtney@gmail.com
              </Typography>
            </div>
          </div>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          href="https://www.linkedin.com/in/courtney-maragh-07770690/"
          sx={{
            my: 2,
            color: "white",
            fontSize: 10,
            position: "relative",
            transition: "1s",
            border: "1px solid #ededed",
            borderRadius: "40px",
            textAlign: "left",
            padding: "1em",
            width: "100%",
          }}
        >
          <div style={{ display: "flex" }}>
            <LinkedInIcon sx={{ marginRight: "10px" }} />
            <Typography>LinkedIn</Typography>
          </div>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          href="https://github.com/cmaragh"
          sx={{
            my: 2,
            color: "white",
            fontSize: 10,
            position: "relative",
            transition: "1s",
            border: "1px solid #ededed",
            borderRadius: "40px",
            textAlign: "left",
            padding: "1em",
            width: "100%",
          }}
        >
          <div style={{ display: "flex" }}>
            <GitHubIcon sx={{ marginRight: "10px" }} />
            <Typography>GitHub</Typography>
          </div>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContactPage;
