import React, { useRef, useEffect, useState, useCallback } from "react";
import { screenHeight, screenWidth } from "../common/commonConstants";
import { Circle } from "./classes/Circle";
import { Hole } from "./classes/Hole";

import ScoreCard from "./components/ScoreCard";
import BucketsExampleModal from "./components/BucketsExampleModal";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  bucketsState,
  shotsFiredState,
  isBucketsGameOnState,
  isBucketsExampleModalFirstTimeOpenState,
} from "../atoms";

import backgroundImageJpeg from "../assets/backgroundImage.jpeg";

const Canvas = () => {
  const [shotsFired, setShotsFired] = useRecoilState(shotsFiredState);
  const [buckets, setBuckets] = useRecoilState(bucketsState);
  const isBucketsGameOn = useRecoilValue(isBucketsGameOnState);
  const isBucketsExampleModalFirstTimeOpen = useRecoilValue(
    isBucketsExampleModalFirstTimeOpenState
  );

  const [circles, setCircles] = useState([]);
  const [isOverlayRemoved, setIsOverlayRemoved] = useState(false);

  const canvasRef = useRef();
  const circlesRef = useRef(circles);
  const holeRef = useRef(new Hole());
  const bucketsRef = useRef(0);
  const isBucketsExampleModalFirstTimeOpenRef = useRef(
    isBucketsExampleModalFirstTimeOpen
  );

  const backgroundImage = new Image();
  backgroundImage.src = backgroundImageJpeg;

  const addToBuckets = useCallback(() => {
    if (bucketsRef.current > 0) {
      setBuckets(bucketsRef.current);
    }
  }, []);

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d", { alpha: false });
    let reqId;
    canvasRef.current.width = screenWidth;
    canvasRef.current.height = screenHeight;
    animate(ctx, circlesRef.current, reqId);
  }, []);

  useEffect(() => {
    circlesRef.current = circles;
  }, [circles]);

  useEffect(() => {
    addToBuckets();
  }, [addToBuckets]);

  useEffect(() => {
    if (isBucketsGameOn) {
      setTimeout(() => {
        setIsOverlayRemoved(true);
      }, 1000);
    } else {
      setIsOverlayRemoved(false);
    }
  }, [isBucketsGameOn]);

  useEffect(() => {
    isBucketsExampleModalFirstTimeOpenRef.current =
      isBucketsExampleModalFirstTimeOpen;
  }, [isBucketsExampleModalFirstTimeOpen]);

  const animate = (ctx, circles, reqId) => {
    setTimeout(() => {
      reqId = requestAnimationFrame(
        animate.bind(this, ctx, circlesRef.current)
      );
      ctx.clearRect(0, 0, screenWidth, screenHeight);
      ctx.drawImage(backgroundImage, 0, 0, screenWidth, screenHeight);
      for (let i = 0; i < circles.length; i++) {
        circles[i].update(ctx);
        if (circles[i]?.offScreen) {
          const newCircles = circlesRef.current;
          newCircles.splice(i, 1);
          circlesRef.current = newCircles;
        }

        if (circles[i].isSuccess) {
          continue;
        }

        if (
          holeRef.current.getHitSideOfHole(
            circles[i].x,
            circles[i].y,
            circles[i].radius
          )
        ) {
          circles[i].isHitSideOfHole = true;
        }

        holeRef.current.hitTopOfHole(circles[i].x, circles[i].y);
        holeRef.current.successHole(circles[i].x, circles[i].y, () => {
          bucketsRef.current++;
          addToBuckets();
          circles[i].isSuccess = true;
        });
      }
      if (isBucketsExampleModalFirstTimeOpenRef.current) {
        holeRef.current.draw(ctx);
      }
    }, 1000 / 950);
  };

  const shootBall = (e) => {
    e.preventDefault();
    let dx = Math.floor((e.clientX * 12) / screenWidth);
    if (screenWidth > 500) {
      dx = Math.floor(1.3 * dx);
    }
    const dy = -Math.floor(((screenHeight - e.clientY) * 38) / screenHeight);
    const newCircle = new Circle(50, screenHeight - 50, dx, dy, 30, Date.now());
    circlesRef.current = [...circlesRef.current, newCircle];
    setShotsFired(shotsFired + 1);
  };

  return (
    <div>
      <ScoreCard shootBall={shootBall} />
      {!isOverlayRemoved && (
        <div
          style={{
            backgroundColor: "black",
            opacity: isBucketsGameOn ? 0 : 0.6,
            width: screenWidth,
            height: screenHeight,
            position: "absolute",
            transition: "1s",
          }}
        ></div>
      )}

      <canvas ref={canvasRef} onClick={shootBall}></canvas>
      <BucketsExampleModal />
    </div>
  );
};

export default Canvas;
