import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";

const WorkCard = ({ title, description, img, site, git }) => {
  return (
    <Card
      sx={{
        maxWidth: 345,
        marginBottom: "20px",
        backgroundColor: "rgba(217, 214, 218, 1)",
      }}
    >
      <CardActionArea>
        <CardMedia component="img" height="140" image={img} alt="..." />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ justifyContent: "space-around" }}>
        <Button size="small" color="primary">
          <a href={git} target="_blank" rel="noreferrer">
            Open Git Repo
          </a>
        </Button>
        <Button size="small" color="primary">
          <a href={site} target="_blank" rel="noreferrer">
            Open App
          </a>
        </Button>
      </CardActions>
    </Card>
  );
};

export default WorkCard;
