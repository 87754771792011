import React from "react";
import Button from "@mui/material/Button";

const MainPageButton = ({ title, href }) => {
  return (
    <Button
      sx={{
        my: 2,
        color: "white",
        fontSize: 10,
        display: "block",
        position: "relative",
        transition: "1s",
        border: "1px solid #ededed",
        borderRadius: "40px",
        padding: "1em",
        margin: ".5em",
        width: "100%",
      }}
      href={href}
    >
      {title}
    </Button>
  );
};

export default MainPageButton;
