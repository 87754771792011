import "./App.css";
import Canvas from "./canvas/Canvas";
import MenuDrawer from "./common/components/MenuDrawer";
import MainPage from "./core/MainPage";

import { RecoilRoot } from "recoil";

import { screenHeight, screenWidth } from "./common/commonConstants";

import "./core/Transitions.css";

function App() {
  return (
    <RecoilRoot>
      <div
        style={{
          position: "fixed",
          width: screenWidth,
          height: screenHeight,
          backgroundColor: "black",
        }}
      ></div>
      <div className="App fade-in">
        <MainPage />
        <Canvas />
      </div>
      <MenuDrawer />
      
    </RecoilRoot>
  );
}

export default App;
